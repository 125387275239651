var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "match-height" },
    [
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-basic")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-limiting")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-multiple")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-drag-drop")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-formatter")], 1),
      _c(
        "b-col",
        { attrs: { lg: "6" } },
        [_c("file-input-contextual-state")],
        1
      ),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-directory")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-slot")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-size")], 1),
      _c("b-col", { attrs: { lg: "6" } }, [_c("file-input-clearing")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }