var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Contextual state feedback" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeContextualState) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("To apply one of the contextual state icons on ")]),
        _c("code", [_vm._v("<b-form-file>")]),
        _c("span", [_vm._v(", set the ")]),
        _c("code", [_vm._v("state")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("false")]),
        _c("span", [_vm._v(" (for invalid), ")]),
        _c("code", [_vm._v("true")]),
        _c("span", [_vm._v(" (for valid), or ")]),
        _c("code", [_vm._v("null")]),
        _c("span", [_vm._v(" (no validation state).")])
      ]),
      _c("b-form-file", {
        attrs: {
          state: Boolean(_vm.file),
          placeholder: "Choose a file or drop it here...",
          "drop-placeholder": "Drop file here..."
        },
        model: {
          value: _vm.file,
          callback: function($$v) {
            _vm.file = $$v
          },
          expression: "file"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }