var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "File name formatting via scoped slot" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSlot) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("You can use the scoped slot ")]),
        _c("code", [_vm._v("file-name")]),
        _c("span", [
          _vm._v(
            " to render the file names. The scoped slot will receive the following properties: "
          )
        ]),
        _c("code", [_vm._v("files,names")])
      ]),
      _c("b-form-file", {
        attrs: { multiple: "" },
        scopedSlots: _vm._u([
          {
            key: "file-name",
            fn: function(ref) {
              var names = ref.names
              return [
                _c("b-badge", { attrs: { variant: "primary" } }, [
                  _vm._v(" " + _vm._s(names[0]) + " ")
                ]),
                names.length > 1
                  ? _c(
                      "b-badge",
                      { staticClass: "ml-1", attrs: { variant: "dark" } },
                      [
                        _vm._v(
                          " + " + _vm._s(names.length - 1) + " More files "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }