var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Control sizing" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Use the ")]),
        _c("code", [_vm._v("size")]),
        _c("span", [
          _vm._v(
            " prop to control the visual size of the input. The default size is considered md (medium). Optional sizes are "
          )
        ]),
        _c("code", [_vm._v("lg")]),
        _c("span", [_vm._v(" (large) and ")]),
        _c("code", [_vm._v("sm")]),
        _c("span", [_vm._v(" (small).")])
      ]),
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Small:",
            "label-for": "file-small",
            "label-cols-sm": "2",
            "label-size": "sm"
          }
        },
        [_c("b-form-file", { attrs: { id: "file-small", size: "sm" } })],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            label: "Default:",
            "label-for": "file-default",
            "label-cols-sm": "2"
          }
        },
        [_c("b-form-file", { attrs: { id: "file-default" } })],
        1
      ),
      _c(
        "b-form-group",
        {
          staticClass: "mb-0",
          attrs: {
            label: "Large:",
            "label-for": "file-large",
            "label-cols-sm": "2",
            "label-size": "lg"
          }
        },
        [_c("b-form-file", { attrs: { id: "file-large", size: "lg" } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }