var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Directory mode" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeDirectory) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("By adding ")]),
        _c("code", [_vm._v("directory")]),
        _c("span", [
          _vm._v(" prop, the user can select directories instead of files.")
        ])
      ]),
      _c("b-form-file", {
        attrs: {
          directory: "",
          placeholder: "Choose a file or drop it here...",
          "drop-placeholder": "Drop file here..."
        },
        model: {
          value: _vm.file,
          callback: function($$v) {
            _vm.file = $$v
          },
          expression: "file"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }