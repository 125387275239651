var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Clearing the file selection" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeClearing) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [
          _vm._v(
            " You can clear the file input's selected files by setting the "
          )
        ]),
        _c("code", [_vm._v("v-model")]),
        _c("span", [_vm._v(" to either ")]),
        _c("code", [_vm._v("null")]),
        _c("span", [_vm._v(" (for single mode) or an empty array ")]),
        _c("code", [_vm._v("[]")]),
        _c("span", [_vm._v(" (for multiple/directory mode).")])
      ]),
      _c(
        "div",
        [
          _c("b-form-file", {
            ref: "file-input",
            model: {
              value: _vm.file,
              callback: function($$v) {
                _vm.file = $$v
              },
              expression: "file"
            }
          }),
          _c(
            "div",
            { staticClass: "demo-inline-spacing" },
            [
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  staticClass: "mr-2 mb-25",
                  attrs: { variant: "outline-primary" },
                  on: { click: _vm.clearFiles }
                },
                [_vm._v(" Reset via method ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "ripple",
                      rawName: "v-ripple.400",
                      value: "rgba(113, 102, 240, 0.15)",
                      expression: "'rgba(113, 102, 240, 0.15)'",
                      modifiers: { "400": true }
                    }
                  ],
                  attrs: { variant: "outline-primary" },
                  on: {
                    click: function($event) {
                      _vm.file = null
                    }
                  }
                },
                [_vm._v(" Reset via v-model ")]
              )
            ],
            1
          ),
          _c("b-card-text", { staticClass: "mt-1 mb-0" }, [
            _vm._v(" Selected file: "),
            _c("strong", [_vm._v(_vm._s(_vm.file ? _vm.file.name : ""))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }