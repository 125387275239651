var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Limiting to certain file types" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeLimiting) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("You can limit the file types by setting the ")]),
        _c("code", [_vm._v("accept")]),
        _c("span", [
          _vm._v(
            " prop to a string containing the allowed file type(s). To specify more than one type, separate the values with a comma. "
          )
        ])
      ]),
      _c("label", { attrs: { for: "wildcard" } }, [_vm._v("Accept all image")]),
      _c("b-form-file", { attrs: { id: "wildcard", accept: "image/*" } }),
      _c("label", { staticClass: "mt-1", attrs: { for: "IANA" } }, [
        _vm._v("Accept specific image formats by IANA type")
      ]),
      _c("b-form-file", {
        attrs: { id: "IANA", accept: "image/jpeg, image/png, image/gif" }
      }),
      _c("label", { staticClass: "mt-1", attrs: { for: "extension" } }, [
        _vm._v("Accept specific image formats by extension")
      ]),
      _c("b-form-file", {
        attrs: { id: "extension", accept: ".jpg, .png, .gif" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }